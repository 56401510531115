<template>
    <div class="modal__input-container">
        <div v-if="type==='check'">
            <label class="modal__check-label" >
                <filling-circle v-if="loading" :size="14" color="#1E3D58" style="display:inline-block;margin-right:8px;"/>
                <input  v-model="value" v-else class="modal__check" type="checkbox"  @change="updateValue" > 
                {{ name }}
            </label>
        </div>
        <div v-else>
             <div class="modal__input-container" v-if="type==='description'">
                <textarea id="edit-id-description" class="modal__description" placeholder=" " :class="{ 'modal__input--infomode':infoMode }" type="text" v-model="value" :disabled="infoMode"></textarea>
                
                <label class="modal__label" for="edit-id-description">{{ name }}</label>
            </div>
           
            <div class="modal__input-container" v-else-if="type==='text'" >
                <span class="modal__input-prefix" v-show="isPhone">+233</span>
                <input v-model="value" class="modal__input"  :id="`edit-id-${name}`"
                :class="{'modal__input--infomode':infoMode, 'modal__input--prefixed':isPhone}" 
                placeholder=" " type="text" :disabled="infoMode">
                <label class="modal__label" :class="{'modal__label--prefixed':isPhone}" for="edit-id-description">{{ name }}</label>
            </div>
           

           <div class="modal__input-container" v-else-if="type==='select'">
                <select :id="`mid-${name}`"  v-model="value" class="modal__input modal--options">
                    <option v-for="(option,index) in options" :value="option.id" :key="index">{{ option.name }}</option>
                </select>
                <label class="modal__label" :for="`mid-${name}`">{{ name }}</label>
            </div>
            
            <button  @click="editToggle" class="modal__edit-input" v-if="editable">
                <!-- refactor conditional display -->
                <div v-show="loading">
                <filling-circle :size="16" color="#fff"/>
                </div>
                <div v-show="!loading">
                    <img v-if="infoMode" src="@/assets/images/pen.svg" height="15" alt="">
                    <img v-else src="@/assets/images/check.svg" height="15" alt="">
                </div>
            </button>
        </div>
       
    </div>
</template>

<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{ FillingCircle },
    props:{
        name:{
            type: String,
        },
        requestKey:{
            type:String,
        },
        defaultValue:{
            type: [String,Boolean],
            default: "",
        },
        type:{
            type: String,
        },
        id:{
            type: String,
        },
        subId:{
            type: String,
        },
        editable:{
            type: Boolean,
            default: true,
        },
        options:{
            type:Array,
        },
        isPhone:{
            type:Boolean
        }
    },
    data(){
        return {
            loading:false,
            infoMode:true,
            valueData:"",
        }
    },
    methods:{
        editToggle(){
            this.infoMode = !this.infoMode
            if(!this.textChange && this.infoMode==true) this.updateValue()
        },

        checkUpdate(){
            if(this.defaultValue !== this.value) this.updateValue()
        },

        updateValue(){

                this.loading = true
                this.$store.dispatch({
                    type:this.load,
                    [this.requestKey]: this.valueToSend,
                    id: this.id,
                    ...(this.subId && {subId: this.subId})

                }).then((response)=>{
                    this.loading = false
                    this.$notify({
                        title: "Update successful!",
                        text:`Successfully changed ${this.modalType} ${this.name}!`,
                        type:"success"
                    })
                    console.log(response)
                }).catch(error=>{
                    this.$notify({
                        title: "Update Failed",
                        text: error,
                        type:"error"
                    })
                    this.loading = false
                    this.value = this.defaultValue
                })    
        },
    },
    computed:{

        valueChange(){
            return this.value === this.defaultValue
        },

        load(){
            return this.$route.meta['M_REQUESTS'].update
        },

        modalType(){
            return this.$route.meta['TITLE_BAR_DATA'].name.singular
        },

        value:{
            get(){
                let valueData = this.valueData
                if(typeof valueData === 'string') valueData = valueData.replace('%','')
                if(this.isPhone) valueData = valueData.replace('+233','')
                
                return valueData
            },
            set(newValueData){
                this.valueData = newValueData
            }
        },

        valueToSend(){
            if(this.type==='check'){
                return Number(this.value)
            }else{
                return this.value
            }
        }
    },
    mounted(){
        
        if (this.defaultValue){
            this.value = this.defaultValue
        }
        console.log(this.value)


       
    }
}
</script>